html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6, button {
  font-family: 'Syncopate', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.no-scroll-bar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.no-scroll-bar::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}


.perspective {
  perspective: 1000px;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  backface-visibility: hidden;
}